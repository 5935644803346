<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-on:submit.prevent="onSubmit" v-if="show">
          <b-form-group
            id="input-predictionDate"
            label="Prediction Date"
            label-for="input-predictionDate"
          >
            <b-form-datepicker
              id="input-predictionDate-input"
              v-model="item.predictionDate"
              required
              class="mb-2"
            ></b-form-datepicker>
          </b-form-group>

          <div role="tablist">
            <b-card
              no-body
              class="mb-1"
              v-for="(horoscope, index) in horoscopes"
              :key="index"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle="'accordion-' + horoscope"
                  variant="info"
                  >{{ horoscope }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + horoscope"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form-group
                    :label="horoscope + ' Short'"
                    :label-for="'textarea-' + horoscope + 'Short'"
                  >
                    <b-form-textarea
                      :id="'textarea-' + horoscope + 'Short'"
                      v-model="item[horoscope + 'Short']"
                      :placeholder="horoscope + 'Short'"
                      rows="3"
                      max-rows="8"
                    ></b-form-textarea>
                  </b-form-group>

                  <b-form-group
                    :label="horoscope + ' Long'"
                    :label-for="'textarea-' + horoscope + 'Long'"
                  >
                    <b-form-textarea
                      :id="'textarea-' + horoscope + 'Long'"
                      v-model="item[horoscope + 'Long']"
                      :placeholder="horoscope + 'Long'"
                      rows="3"
                      max-rows="8"
                    ></b-form-textarea>
                  </b-form-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="form-submit">
            <b-button type="submit" variant="secondary"
              ><b-spinner small v-if="isLoading"></b-spinner> Submit</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
.btn-info {
  background-color: #b5b5c3;
  border-color: #b5b5c3;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      isLoading: false,
      item: {},
      show: true,
      id: null,
      isEditMode: false,
      horoscopes: [
        "aries",
        "taurus",
        "gemini",
        "cancer",
        "leo",
        "virgo",
        "libra",
        "scorpio",
        "sagittarius",
        "capricorn",
        "aquarius",
        "pisces"
      ]
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        this.isLoading = true;
        ApiService.post("dailyHoroscope/upsert", this.item)
          .then(({ data }) => {
            this.isLoading = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Item has been saved sucessfully!", {
                title: "Success",
                variant: "success"
              });
            } else {
              this.$bvToast.toast("Something went wrong while saving item...", {
                title: "Error",
                variant: "danger"
              });
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast("Something went wrong while saving item...", {
              title: "Error",
              variant: "danger"
            });
          });
      });
    },
    getItem: function() {
      const id = this.$route.params.id || this.item.id || this.$route.query.id;
      this.isEditMode = !!id;

      if (this.isEditMode) {
        return new Promise(resolve => {
          ApiService.get("dailyHoroscope", { id: id })
            .then(({ data }) => {
              const item =
                data.response && data.response.items.length > 0
                  ? data.response.items[0]
                  : null;
              if (item) {
                this.item = item;
              } else {
                this.item = {};
                this.isEditMode = false;
              }
              resolve();
            })
            .catch(() => {
              this.$bvToast.toast(
                "Something went wrong while retrieving data from external server...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            });
        });
      }
    }
  },
  mounted() {
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daily Horoscope", route: "dailyHoroscope" },
      { title: this.isEditMode ? "Edit Horoscope" : "Add Horoscope" }
    ]);
  }
};
</script>
